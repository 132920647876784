import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import SocialMediaLinks from "@one-builder/gatsby-theme-ob-master/src/components/social-links"
import Logo from "@one-builder/gatsby-theme-ob-master/src/components/logo"
import Navigation from "@one-builder/gatsby-theme-ob-master/src/components/layout/navigation/navigation"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import { FaPhone } from "react-icons/fa"
import AlertBanner from "@one-builder/gatsby-theme-ob-master/src/components/layout/alert-banner"
import headerStyles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"
import Headroom from "react-headroom"

const Header = ({ is_landing }) => {
  let landing_page = ""
  is_landing ? (landing_page = "landing-page") : (landing_page = "")
  const metadata = useGetTokens()

  let [headerActive, setHeaderActive] = useState(true)
  let [showFixedSub, setShowFixedSub] = useState("")

  useEffect(() => {
    if (headerActive) {
      setShowFixedSub("")
    } else {
      setShowFixedSub("fixed-cta-sub-active")
    }
  }, [headerActive])

  return (
    <>
      <Headroom
        className={landing_page}
        onPin={() => {
          setHeaderActive(true)
        }}
        onUnpin={() => {
          setHeaderActive(false)
        }}
        onUnfix={() => {
          setHeaderActive(true)
        }}
      >
        <AlertBanner metadata={metadata} />
        <div className={headerStyles.topBar} key="1">
          <div className={headerStyles.container}>
            <div className={headerStyles.sub}>
              <span>
                <FaPhone />{" "}
                <a
                  href={`tel:${metadata.CompanyPhone}`}
                  title={metadata.CompanyPhone}
                >
                  {metadata.CompanyPhone}
                </a>
              </span>
            </div>
            <div className={headerStyles.sub}>
              <SocialMediaLinks />
            </div>
            <div className={headerStyles.sub}>
              <Link className={headerStyles.cta} to="#lets-connect">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <header className={headerStyles.header} key="2">
          <div
            className={`${headerStyles.container} ${headerStyles.flexWrapper}`}
          >
            <div className={headerStyles.logo}>
              <Link alt={metadata.CompanyName} to="/">
                <div
                  role="img"
                  aria-label={convertTokens("{{CompanyName}}", metadata)}
                >
                  <Logo />
                </div>
              </Link>
            </div>
            <Navigation />
          </div>
        </header>
      </Headroom>
      <Link
        id={headerStyles.fixedCtaSub}
        className={showFixedSub}
        to="#lets-connect"
      >
        Contact Us
      </Link>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
