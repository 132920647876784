import React, { useEffect } from "react"
import PropTypes from "prop-types"

import Header from "./header"
import SEO from "@one-builder/gatsby-theme-ob-master/src/components/layout/seo"
import BreadCrumbs from "@one-builder/gatsby-theme-ob-master/src/components/layout/breadcrumbs"
import getBreadCrumbs from "@one-builder/gatsby-theme-ob-master/src/helpers/getBreadCrumbs"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import Footer from "./footer"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children, location, is_landing = false, seo_data = {} }) => {
  // Add homepage or subpage to body tag
  useEffect(() => {
    let body
    if (document) {
      body = document.getElementsByTagName("body")[0]
      if (location) {
        body ? body.setAttribute("id", "subpage") : body.setAttribute("id", "")
      } else {
        body ? body.setAttribute("id", "homepage") : body.setAttribute("id", "")
      }
    }
  }, [])

  // If location passed to layout, set breadcrumbs
  let breadcrumbs
  if (location && !is_landing) {
    breadcrumbs = getBreadCrumbs(location)
  } else {
    breadcrumbs = undefined
  }

  // Get meta data
  const metadata = useGetTokens()

  return (
    <>
      <SEO
        metadata={metadata}
        seo_data={convertTokens(JSON.stringify(seo_data), metadata)}
        location={location}
      />
      <Header siteTitle={metadata.CompanyName} is_landing={is_landing} />
      {breadcrumbs ? <BreadCrumbs breadcrumbs={breadcrumbs} /> : null}
      <main>{children}</main>
      <Footer metadata={metadata} is_landing={is_landing} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
