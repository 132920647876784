import { Link } from "gatsby"
import React from "react"
import SocialMediaLinks from "@one-builder/gatsby-theme-ob-master/src/components/social-links"
import { FaPhone } from "react-icons/fa"
import Logo from "@one-builder/gatsby-theme-ob-master/src/components/logo"
import Image from "../Image"

import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

const Footer = ({ metadata, is_landing }) => {
  let landing_page = ""
  is_landing ? (landing_page = "landing-page") : (landing_page = "")
  return (
    <>
      <div className={landing_page}>
        <footer>
          <div className={styles.container}>
            <div className={styles.footer_sub}>
              <div className={styles.footer_logo}>
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <p>{metadata.CompanySlogan}</p>
              <div className={styles.contactbox}>
                <span>
                  {" "}
                  <FaPhone />
                  <a
                    href={`tel:${metadata.CompanyPhone}`}
                    title={metadata.CompanyPhone}
                  >
                    {metadata.CompanyPhone}
                  </a>
                </span>
                <SocialMediaLinks />
              </div>
            </div>
            <div className={styles.footer_sub}>
            <div style={{ maxWidth: `300px` }}>
                <Image alt="Customer Acquisition Specialist" filename="cas-certified.png" />
            </div>
            <div style={{ maxWidth: `300px` }}>
                <Image alt="Customer Value Optimization Specialist" filename="cvos-certified.png" />
            </div>
            <div style={{ maxWidth: `300px` }}>
                <Image alt="Ecommerce Marketing Specialist" filename="ems-certified.png" />
            </div>
            <div style={{ maxWidth: `300px` }}>
                <Image alt="Email Marketing Specialist" filename="email-certified.png" />
            </div>
            </div>
          </div>
        </footer>
        <div className={styles.footerBar}>
          <div className={styles.container}>
            <div>
              © {new Date().getFullYear()}
              {` `}
              {metadata.CompanyName}
            </div>
            <div>
              <Link to="/privacy-policy/">Privacy Policy</Link>
              <Link to="/terms-and-conditions/">Terms of Use</Link>
              <a href="/sitemap.xml">Sitemap</a>
            </div>
          </div>
        </div>
      </div>
      {/* Klaviyo Tracking */}
      <script
        type="application/javascript"
        async
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.GATSBY_KLAVIYO_PUBLIC_KEY}`}
      ></script>
    </>
  )
}

export default Footer
